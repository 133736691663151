import { Fragment, useEffect } from 'react'
import RootElement from './src/components/RootElement'
import { DistinctIDStoreUpdater, distinctIdStore } from './src/store/Store'

import './src/fonts/DomaineText-Light.ttf'
import './src/fonts/DomaineText-Regular.ttf'
import './src/fonts/PPObjectSans-Regular.ttf'
import './src/fonts/PPObjectSans-Heavy.ttf'
import './src/styles/main.css'

interface HotjarWindow extends Window {
  hj?: any
  _hjSettings?: any
}

const HotjarScript = () => {
  useEffect(() => {
    const hotjar = (h: HotjarWindow, doc: Document) => {
      h.hj =
        h.hj ||
        function () {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: 4933567, hjsv: 6 }
      const headElement = doc.getElementsByTagName('head')[0]
      const scriptElement = doc.createElement('script')
      scriptElement.async = true
      scriptElement.src = `https://static.hotjar.com/c/hotjar-${h._hjSettings.hjid}.js?sv=${h._hjSettings.hjsv}`
      headElement.appendChild(scriptElement)
    }
    hotjar(window, document)
  })

  return <Fragment />
}

const HubspotScript = () => {
  useEffect(() => {
    const hubspot = (doc: Document) => {
      const headElement = doc.getElementsByTagName('head')[0]
      const scriptElement = doc.createElement('script')
      scriptElement.async = true
      scriptElement.id = 'hs-script-loader'
      scriptElement.type = 'text/javascript'
      scriptElement.src = 'https://js.hs-scripts.com/39610279.js'
      headElement.appendChild(scriptElement)
    }
    hubspot(document)
  })

  return <Fragment />
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <Fragment>
      <HotjarScript />
      <HubspotScript />
      <DistinctIDStoreUpdater
        store={distinctIdStore}
        location={props.location}
      />
      {element}
    </Fragment>
  )
}

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}
